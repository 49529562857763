import React from 'react';
import { Link } from "react-router-dom";

const CategoryNews = ({ businessNews, headerHide }) => {

    return (
        <div className="row">
            <div className="col-12">
                <div className="businerss_news">
                    {headerHide ? '' :
                        <div className="row">
                            <div className="col-6 align-self-center">
                                <h2 className="widget-title">Business News</h2>
                            </div>
                            <div className="col-6 text-right align-self-center">
                                <Link to="/" className="see_all mb20">See All</Link>
                            </div>
                        </div>}
                    <div className="row">
                        <div className="col-12">
                            {businessNews.sort(function (b, a) { return a.date - b.date; }).map((item, i) => (
                                <div key={i} className="single_post post_type3 post_type12 mb30">
                                    <div className="post_img">
                                        <div className="img_wrap">
                                            <Link to={"/specific-news/" + item.Id}><img className="general-section-limited-image" src={item.Image} alt="thumb" /></Link>
                                        </div>
                                    </div>
                                    <div className="single_post_text">
                                        <div className="space-10" />
                                        <h4 className="general-section-limited-text"><b>{item.Title}</b></h4>
                                        <div className="space-10" />
                                        <h4 className="general-section-date">{item.Date}</h4>
                                        <p className="post-p general-section-limited-text-1">{item.Body}</p>
                                        <div className="space-20" />
                                        <Link to={"/specific-news/" + item.Id} className="readmore">ಹೆಚ್ಚಿನ ಮಾಹಿತಿ</Link>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryNews;