import React, { Fragment, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { firestore } from "../../Config/FbConfig";
import { doc, getDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

// images
import author2 from '../../doc/img/author/author2.png';

// images
import banner1 from '../../doc/img/bg/square1.jpg';
import banner2 from '../../doc/img/bg/square2.jpg';
import banner3 from '../../doc/img/bg/banner1.jpg';
import banner4 from '../../doc/img/bg/banner2.jpg';

const PostTwoPage = () => {

    const { id, date } = useParams()
    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);
    const location = useLocation();

    React.useEffect(() => {
        async function getData() {
            const docRef = doc(firestore, "AdminNews", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []

                const docRef_1 = doc(firestore, "Public", docSnap.data().Category);
                const docSnap_1 = await getDoc(docRef_1);
                if (docSnap_1.exists()) {
                    Object.entries(docSnap_1.data()).forEach(([key, value]) => {
                        if(key === id){
                            Data.push(Object.assign(
                                {
                                    Id: id,
                                    Image: docSnap.data().Image,
                                    Header: docSnap.data().Header,
                                    Description: docSnap.data().Description,
                                    Description1: docSnap.data().Description1,
                                    Video: docSnap.data().Video == "" ? "None" : "https://www.youtube.com/embed/" + docSnap.data().Video.split("=")[1],
                                    Category: docSnap.data().Category,
                                    Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                                }
                            ))
        
                            setdata(Data)
                            setloader(false)
                        }
                    })
                } else {
                    setdata([])
                    setloader(false)
                }
            }
        }
        getData()
    }, [location])

    // Define the meta information
    const title = loader === true ? '' : data[0].Header;
    const description = loader === true ? '' : data[0].Description;
    const imageUrl = loader === true ? '' : data[0].Image; // Use the URL of your image

    return (
        <div>
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={imageUrl} />
                </Helmet>
                <div className="archives post post1">
                    <BreadCrumb className="shadow5 padding-top-30" title={loader === true ? "" : data[0].Category} type="None" />
                    <span className="space-30" />
                    <div className="container">
                        {
                            loader === true ? "Loading.." :
                                <div className="row">
                                    <div className="col-12 col-md-10 col-lg-8 m-auto">
                                        <div className="single_post_heading"><h1>{data[0].Header}</h1></div>
                                        <div className="space-20" />
                                        <div className="author">
                                            <div className="author_img">
                                                <div className="author_img_wrap">
                                                    <img src={author2} alt="author2" />
                                                </div>
                                            </div>
                                            <ul><li>{data[0].Date}</li></ul>
                                        </div>
                                        <div className="space-20" />
                                        <img className="Specific-section-limited-image" src={data[0].Image} alt="thumb" />
                                        <div className="space-20" />
                                        <p className="justify">{data[0].Description}</p>
                                        {/*video start*/}
                                        <div className="video_img" style={data[0].Video == "None" ? { display: "none" } : { display: "block" }}>
                                            <div className="space-20" />
                                            {
                                                <div class="video-container">
                                                    <iframe class="embedded-video-16-9" src={data[0].Video} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </div>
                                            }
                                            <div className="space-20" />
                                        </div>
                                        <br />
                                        <div className="banner1">
                                            <a href="https://www.aranyaorganic.com/" target="blank">
                                                <img src={banner3} alt="bannerImg" />
                                            </a>
                                        </div>
                                        <div className="space-20" />
                                        <p className="justify">{data[0].Description1}</p>
                                        <div className="space-40" />
                                    </div>
                                    <div className="col-md-2 col-lg-4">
                                        <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner1} alt="thumb" /></a></div>
                                        <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div>
                                        <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner1} alt="thumb" /></a></div>
                                    </div>
                                </div>
                        }
                        <div className="desk-only">
                            <div className="row">
                                <div className="col-lg-12 m-auto">
                                    <div className="banner1">
                                        <a href="https://www.aranyaorganic.com/" target="blank">
                                            <img src={banner4} alt="bannerImg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div><br />
                    </div>
                </div>
                <div className="space-60" />
            </Fragment>
        </div>
    )
};

export default PostTwoPage;
