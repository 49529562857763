import React  from 'react';
import { Link } from "react-router-dom";
import author1 from '../../doc/img/author/author2.png';

const Policy = () => {
    return (
        <div className="container">
            <div className="space-50" />
            <div className="author_about">
                <div className="author_img">
                    <div className="author_wrap">
                        <img src={author1} alt="author1" />
                    </div>
                </div>
                <div className="author_content"><Link to="/">Editors Angle - Privacy Policy</Link>
                    <ul className="inline">
                        <li>August 1, 2022</li>
                    </ul>
                </div>
                <p>The Information We Collect</p>
                <p>Information that you provide on our digital platforms</p>
                <p>While registering and/or subscribing to the content on our website (some may be optional):</p>
                <ul>
                    <li>Name, DOB, Gender, Email ID, Address, Mobile Number, Preferences (for example based on topic, author and cities)</li>
                </ul>
                <p>While commenting on an article:</p>
                <ul>
                    <li>Email ID</li>
                </ul>
                <p>Information that we collect as you use our services</p>
                <ul>
                    <li>Device Information:</li>
                    <li>Device Attributes: Information such as the operating system, hardware and software versions, browser types</li>
                    <li>Device Operations: Information about operations performed on the device, such as mouse clicks, article scrolls, browser actions</li>
                    <li>Identifiers: Unique identifiers, Device IDs, other identifiers</li>
                    <li>Network and Connections: Information such as time zone, geographic location, IP address</li>
                    <li>Cookie Data: Data from cookies stored on your device, including cookie IDs and settings</li>
                </ul>
                <p>Information that we collect about your preferences</p>
                <p>When you navigate on our website, we carry out profiling to collect your preferences related to:</p>
                <ul>
                    <li>Topics</li>
                    <li>Authors</li>
                    <li>Cities</li>
                </ul>
                <p>Information from partners</p>
                <p>When you subscribe to our services through a third-party website we have partnered with, we may collect information that you provide about yourself on that website.</p>
                <p>While subscribing, other readers can mention you as a referrer and provide your name and telephone number.</p>
                <p>How We Collect and Use Your Information</p>
                <p>We collect your personal information (and other information we collect from you) to:</p>
                <ul>
                    <li>Provide services through our website(s) and app(s)</li>
                    <li>Enhance your experience while using our services</li>
                    <li>Personalise content and advertisements you see</li>
                    <li>Communicate with you: We use information that we collect, such as your email address, to interact with you directly. For example, we might send you reminders when your subscription is about to expire or inform you when we have an offer that may be of interest to you.</li>
                </ul>
                <p>We share some of the information you provide us with:</p>
                <ul>
                    <li>Demand-side providers for targeted advertisement using third-party cookies (Refer: Information collected by partners)</li>
                    <li>Third-party agencies for analytics</li>
                </ul>
                <p>FAQs on Cookies We Use (To Collect Your Information)</p>
                <p>1.	What are cookies?</p>
                <p>Ans : A cookie is a small piece of information sent from websites you visit (including The Hindu Group websites) to your computer to help identify you quickly. Cookies are not used to identify individuals personally and no personal information is stored in relation to the cookies.</p>
                <p>2.	What are site functionality cookies?</p>
                <p>Ans : Our websites use browser cookies to determine whether a user is signed in or not. We also use cookies to ensure that a piece of information is only displayed to users a certain number of times.</p>
                <p>3.	What are third-party cookies?</p>
                <p>Ans : We use third-party advertising companies to display advertising when you visit our websites. Cookies are used in the software that displays these advertisements, known as third-party cookies. These cookies may be used to help display more relevant advertisements to you.</p>
                <p>These companies may use information (not including your name or email address) about your visits to this and other sites in order to provide advertisements about goods and services of interest to you.</p>
                <p>Third-party cookies may include behavioural targeting cookies. These are also intended to help display more relevant advertisements to you by using information about other sites you may have visited previously.</p>
                <p>Third-party cookies also include those from our data analytics providers. These allow us to analyse the use of our sites and do not identify users individually.</p>
                <p>4.	How can you manage your cookies?</p>
                <p>Ans : We use a Consent Management Platform that helps you with managing your preferences with Cookies used on our websites.</p>
                <p>Managing Your Personal Data Stored with Us</p>
                <p>Within the scope of services that we offer and ones which you have subscribed to, you have choices to manage, review and update your information.</p>
                <ul>
                    <li>You have the right to request us for copies of your personal data that is with us. We may charge you a small fee for this service.</li>
                    <li>You have the right to request that we correct any information you believe is inaccurate. You also have the right to request us to complete the information you believe is incomplete.</li>
                    <li>You have the right to request that we erase your personal data, under certain conditions.</li>
                    <li>You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                    <li>You have the right to object to us processing your personal data, under certain conditions.</li>
                </ul>
                <p>You can write to with any of the above requests and we will respond within 30 days.</p>
                <p>Security of Data You Share With Us</p>
                <p>We implement appropriate security mechanisms that continuously protect your information from unauthorised access and disclosure.  We limit access (to personal information) to those who need it in order to process it.</p>
                <p>Changes to This Policy</p>
                <p>We change this Privacy Policy whenever such a need arises. Your rights under this Privacy Policy will not be reduced without your explicit consent. The last date on which this policy was changed is notified at the bottom of this webpage. Whenever there are significant changes, we will take measures to notify you.</p>
                <p>Got Questions/Concerns?</p>
                <p>ou can write to if you require any clarifications regarding this policy.</p>
            </div>
            <div className="space-50" />
        </div>
    )
};

export default Policy;