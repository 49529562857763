import React, { Component } from 'react';
import { useParams, useLocation } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { firestore } from "../../Config/FbConfig";
import { doc, getDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

// images
import author2 from '../../doc/img/author/author2.png';

// images
import banner1 from '../../doc/img/bg/square1.jpg';
import banner2 from '../../doc/img/bg/square2.jpg';
import banner3 from '../../doc/img/bg/banner1.jpg';
import banner4 from '../../doc/img/bg/banner2.jpg';

class PostTwoPage extends Component {
  state = {
    imageUrl: '',
    title: 'My Page Title',
    data: [],
    loader: true
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { id } = this.props.match.params;
    const { pathname } = this.props.location;

    try {
      const docRef = doc(firestore, "AdminNews", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const Data = [
          Object.assign(
            {
              Id: id,
              Image: docSnap.data().Image,
              Header: docSnap.data().Header,
              Description: docSnap.data().Description,
              Description1: docSnap.data().Description1,
              Video: docSnap.data().Video == "" ? "None" : "https://www.youtube.com/embed/" + docSnap.data().Video.split("=")[1],
              Category: docSnap.data().Category,
            }
          )
        ];

        this.setState({
          imageUrl: docSnap.data().Image,
          title: docSnap.data().Header,
          data: Data,
          loader: false
        });
      } else {
        this.setState({
          data: [],
          loader: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { imageUrl, title, data, loader } = this.state;
    const { date } = this.props.match.params;

    return (
      <div>
        <Helmet>
          <meta property="og:image" itemprop="image" content={imageUrl} />
          <meta property="og:title" content={title} />
          <meta property='og:locale' content='ar_AR' />
        </Helmet>
        <React.Fragment>
          <div className="archives post post1">
            <BreadCrumb className="shadow5 padding-top-30" title={loader === true ? "" : data[0].Category} type="None" />
            <span className="space-30" />
            <div className="container">
              {
                loader === true ? "Loading.." :
                  <div className="row">
                    <div className="col-12 col-md-10 col-lg-8 m-auto">
                      <div className="single_post_heading"><h1>{data[0].Header} 1</h1></div>
                      <div className="space-20" />
                      <div className="author">
                        <div className="author_img">
                          <div className="author_img_wrap">
                            <img src={author2} alt="author2" />
                          </div>
                        </div>
                        <ul><li>{date}</li></ul>
                      </div>
                      <div className="space-20" />
                      <img className="Specific-section-limited-image" src={data[0].Image} alt="thumb" />
                      <div className="space-20" />
                      <p className="justify">{data[0].Description}</p>
                      {/*video start*/}
                      <div className="video_img" style={data[0].Video == "None" ? { display: "none" } : { display: "block" }}>
                        <div className="space-20" />
                        {
                          <div class="video-container">
                            <iframe class="embedded-video-16-9" src={data[0].Video} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        }
                        <div className="space-20" />
                      </div>
                      <br />
                      <div className="banner1">
                        <a href="https://www.aranyaorganic.com/" target="blank">
                          <img src={banner3} alt="bannerImg" />
                        </a>
                      </div>
                      <div className="space-20" />
                      <p className="justify">{data[0].Description1}</p>
                      <div className="space-40" />
                    </div>
                    <div className="col-md-2 col-lg-4">
                      <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner1} alt="thumb" /></a></div>
                      <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div>
                      <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner1} alt="thumb" /></a></div>
                    </div>
                  </div>
              }
              <div className="desk-only">
                <div className="row">
                  <div className="col-lg-12 m-auto">
                    <div className="banner1">
                      <a href="https://www.aranyaorganic.com/" target="blank">
                        <img src={banner4} alt="bannerImg" />
                      </a>
                    </div>
                  </div>
                </div>
              </div><br />
            </div>
          </div>
          <div className="space-60" />
        </React.Fragment>
      </div>
    );
  }
}

export default PostTwoPage;
