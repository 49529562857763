import React, { useState } from 'react';
import { Link } from "react-router-dom";
import author1 from '../../doc/img/author/author2.png';

const AboutUsPage = () => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <div className="container">

            <div className="space-50" />
            <div className="author_about">
                <div className="author_img">
                    <div className="author_wrap">
                        <img src={author1} alt="author1" />
                    </div>
                </div>
                <div className="author_content"><Link to="/">Editors Angle</Link>
                    <ul className="inline">
                        <li>News Writer</li>
                        <li>Since: August 1, 2022</li>
                    </ul>
                </div>
                <p>ಇತ್ತೀಚೆಗೆ ಕಂಡಿದ್ದು ಕೇಳಿದ್ದು ಸಿಕ್ಕಿದ್ದು ಹೆಕ್ಕಿದ್ದು ಎಲ್ಲವೂ ಸುದ್ದಿ ಎನ್ನುವಂತಾಗಿದೆ ಕೆಲವೊಮ್ಮೆ ಮಾಧ್ಯಮಗಳು ಪ್ರಕಟಿಸಿದ ಎಲ್ಲವೂ ಸುದ್ದಿ ಆಗುವುದಿಲ್ಲ ಸುದ್ದಿಯಾಗುವ ಎಷ್ಟೋ ವಿಚಾರಗಳನ್ನು ಮಾಧ್ಯಮಗಳು ಪ್ರಕಟಿಸುವುದಿಲ್ಲ ಇದು  ನೈಜ ಸುದ್ದಿ ನಿರೀಕ್ಷಕರ ಸಾಮಾನ್ಯ ಅಭಿಪ್ರಾಯ.</p>
                <p>ಹೀಗೆ ಸುದ್ದಿ ವಿಚಾರದಲ್ಲಿ ಎದ್ದಿರುವ ಅನೇಕ ಗೊಂದಲಗಳ ನಡುವೆ ನಿಜವಾದ ಸುದ್ದಿ ಯಾವುದು ಸುದ್ದಿಯನ್ನು ಯಾವುದು.ಸಂಪಾದಕರಿ ಯಾವ ಆಂಗಲ್ ನಲ್ಲಿ ಸುದ್ದಿಯನ್ನು ನೋಡಬೇಕು ಎನ್ನುವ ಪುಟ್ಟ ಪ್ರಯತ್ನವಾಗಿ ಎಡಿಟರ್ಸ್ ಆಂಗಲ್ ಎನ್ನುವ ಸಣ್ಣ ವೆಬ್ ಪೋರ್ಟಲ್ ಸುದ್ದಿ ವಾಹಿನಿಯನ್ನು ನಿಮ್ಮ ಮುಂದಿಡುತ್ತಿದ್ದೇವೆ ದಯವಿಟ್ಟು ಪರೀಕ್ಷಿಸಿ  ಪ್ರೋತ್ಸಾಹಿಸಿ ಚೆನ್ನಾಗಿದ್ದರೆ ಪ್ರಶಂಸಿಸಿ.</p>
            </div>
            <div className="space-50" />
        </div>
    )
};

export default AboutUsPage;