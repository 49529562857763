import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';
import FontAwesome from "../uiStyle/FontAwesome";
import { firestore } from "../../Config/FbConfig";
import { doc, getDoc } from 'firebase/firestore';

const TopBar = ({ className, dark }) => {

    const [swiper, setSwiper] = useState(null);
    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    React.useEffect(() => {
        async function getData() {
            const docRef = doc(firestore, "Public", "BreakingNews");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []
                Object.entries(docSnap.data()).forEach(([key, value]) => {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                            Header: value.Header
                        }
                    ))
                });
                setdata(Data.sort(function (b, a) { return a.Date - b.Date; }))
                setloader(false)

            } else {
                setdata([])
                setloader(false)
            }
        }
        getData()
    }, [])

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const params = {
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    };

    return (
        <div className={`topbar ${className ? className : ''}`} id="top">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 align-self-center">
                        <div className={`trancarousel_area ${dark ? 'white' : ''}`}>
                            <p className="trand">ಬ್ರೇಕಿಂಗ್</p>
                            <div className="nav_style1">
                                {loader === true ? "Loading.." :
                                    <Swiper getSwiper={setSwiper} className="trancarousel" {...params}>
                                        {data.map((item, i) => (
                                        <div className="trancarousel_item"><b><p><Link to={'/specific-news/' + item.Id}>{item.Header}</Link></p></b></div>
                                        ))}
                                    </Swiper>
                                }
                                <div className="navBtns">
                                    <button className="navBtn prevBtn" onClick={goPrev}><FontAwesome name="angle-left" /></button>
                                    <button className="navBtn nextBtn" onClick={goNext}><FontAwesome name="angle-right" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="top_date_social text-right">
                            <div className={`paper_date ${dark ? 'white' : ''}`}>
                                <p>{new Date().toLocaleString('default', { weekday: 'long' })}, {new Date().toLocaleString('default', { month: 'long' })} {new Date().toLocaleString("en-US", { day: '2-digit' })}, {new Date().getFullYear()}</p>
                            </div>
                            <div className={`social1 ${dark ? 'white' : ''}`}>
                                <ul className="inline">
                                    <li><a target="_blank" href="https://twitter.com/AngleEditors"><FontAwesome name="twitter" /></a></li>
                                    <li><a target="_blank" href="https://www.facebook.com/profile.php?id=100083784889417"><FontAwesome name="facebook-f" /></a></li>
                                    <li><a target="_blank" href="https://www.youtube.com/channel/UCt8EyS2rBZYohtfMrv3Fofw"><FontAwesome name="youtube-play" /></a></li>
                                    <li><a target="_blank" href="https://www.instagram.com/editorsangle"><FontAwesome name="instagram" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;