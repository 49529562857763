import React, {useState} from 'react';
import {Link} from "react-router-dom";
import FooterCopyright from "../FooterCopyright";
import flogo from '../../doc/img/logo/footer_logo.png';

const FooterArea = ({className}) => {
    const [email, setEmail] = useState('');
    const submitHandler = e => {
        e.preventDefault();
        setEmail('')
    };
    return (
        <div className={`footer footer_area1 ${className ? className : ''}`}>
            <div className="container">
                <div className="cta">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <div className="footer_logo logo">
                                <Link to="/">
                                    <img src={flogo} alt="logo"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterCopyright/>
        </div>
    );
};

export default FooterArea;