import React, { Fragment, useState } from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import CategoryNews from "../../components/CategoryNews";
import { useParams, Link, useLocation } from "react-router-dom";
import { firestore } from "../../Config/FbConfig";
import { doc, getDoc } from 'firebase/firestore';

// images
import banner1 from '../../doc/img/bg/square1.jpg';
import banner2 from '../../doc/img/bg/square2.jpg';
import banner3 from '../../doc/img/bg/banner1.jpg';
import banner4 from '../../doc/img/bg/banner2.jpg';

const GeneralPage = () => {
    const { id } = useParams()
    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);
    const location = useLocation();

    React.useEffect(() => {
        async function getData() {
            const docRef = doc(firestore, "Public", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []
                Object.entries(docSnap.data()).forEach(([key, value]) => {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Image: value.Image,
                            Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                            date: value.Date != undefined ? value.Date : "9999",
                            Title: value.Header,
                            Body: value.Description,
                        }))
                });
                setdata(Data.sort(function (b, a) { return a.date - b.date; }))
                setloader(false)
            } else {
                setdata([])
                setloader(false)
            }
        }
        getData()
    }, [location])

    return (
        <Fragment>
            <BreadCrumb title={id} type="List" />
            <div className="archives padding-top-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="businerss_news">
                                <div className="row">
                                    <div className="col-12">
                                        {loader === true ? "Loading.." :
                                            <CategoryNews headerHide={true} businessNews={data} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner1} alt="thumb" /></a></div>
                            <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-70" />
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 m-auto">
                        <div className="banner1">
                            <a href="https://www.aranyaorganic.com/" target="blank">
                                <img src={banner3} alt="bannerImg"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default GeneralPage;