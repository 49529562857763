import React, { Component } from 'react';
import { Link } from "react-router-dom";
import FontAwesome from '../uiStyle/FontAwesome';
import logo from '../../doc/img/logo/logo.png';
import tp_banner from '../../doc/img/bg/banner1.png';
import "./indexcss.css";

export default class index extends Component {

    handleClick = () => {
        document.getElementById("mySidenav").style.width = "250px";
    }

    render() {
        return (
            <div className={`logo_area`}>
                <div id="mySidenav" className="sidenav">
                    <a href="/">* ಮುಖಪುಟ</a>
                    <a href="/news-list/StateNews">* ರಾಜ್ಯ</a>
                    <a href="/news-list/NationalNews">* ರಾಷ್ಟ್ರ</a>
                    <a href="/news-list/Crime">* ಕ್ರೈಂ</a>
                    <a href="/news-list/Cinema">* ಸಿನಿಮಾ</a>
                    <a href="/news-list/LifeStyle">* ಜೀವನ ಶೈಲಿ</a>
                    <a href="/news-list/Sports">* ಕ್ರೀಡೆ</a>
                    <a href="/news-list/Religion">* ಧಾರ್ಮಿಕ</a>
                    <a href="/news-list/Education">* ಶಿಕ್ಷಣ</a>
                    <a href="/news-list/Health">* ಆರೋಗ್ಯ</a>
                    <a href="/news-list/Business">* ವಾಣಿಜ್ಯ</a>
                    <a href="/news-list/FoodSection">* ಆಹಾರ</a>
                </div>
                <div className="container">
                    <div className="row desk-only">
                        <div className="col-lg-6 col-sm-6">
                            <div className="logo">
                                <a href="/"><img src={logo} alt="logo" className="img-fluid" /></a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                            <div className="banner1">
                                <Link to="#">
                                    <img src={tp_banner} alt="banner" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mob-only">
                        <div className="col-8">
                            <div className="logo">
                                <a href="/"><img src={logo} alt="logo" className="img-fluid" /></a>
                            </div>
                        </div>
                        <div className="col-2 mob-icon" onClick={this.handleClick}><FontAwesome name="bars" /></div>
                    </div>
                </div>
                <hr />
            </div>
        )
    }
}
