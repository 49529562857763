import React, { Fragment } from 'react';
import PostCarousel from "../../components/PostCarousel";
import LineBreaking from "../../components/LineBreaking";
import WidgetTab from "../../components/WidgetTab";
import FeatureNews from "../../components/FeatureNews";
import MostView from "../../components/MostView";
import BannerSection from '../../components/BannerSection';

// images
import banner1 from '../../doc/img/bg//square1.jpg';
import banner2 from '../../doc/img/bg//square2.jpg';

const HomePage = () => {
    return (
        <Fragment>
            {/*Top Section*/}
            <LineBreaking className="fifth_bg mob-only" />
            <PostCarousel className="fifth_bg desk-only" />
            <BannerSection className="mob-only" Img="banner1.jpg" link="https://www.aranyaorganic.com/" /><br />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <FeatureNews category="FeatureSection" Tag="ಪ್ರಮುಖ ಸುದ್ದಿಗಳು" />
                        <div className="row">
                            <div className="col-lg-6"><div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner1} alt="thumb" /></a></div></div>
                            <div className="col-lg-6 desk-only"><div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div></div>
                        </div>

                        <FeatureNews category="Cinema" Tag="ಸಿನಿಮಾ" />
                        <BannerSection className="mob-only desk-only" Img="banner1.jpg" link="https://www.aranyaorganic.com/" /><br className="desk-only" />
                        <div className="mob-only banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div>

                        <FeatureNews category="Crime" Tag="ಕ್ರೈಂ" />
                        <BannerSection className="desk-only" Img="banner1.jpg" link="https://www.aranyaorganic.com/" /><br className="desk-only" />
                        <div className="mob-only banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div>

                        <FeatureNews category="Sports" Tag="ಕ್ರೀಡೆ" />
                        <div className="mob-only banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div>
                    </div>
                    <div className="col-lg-4">
                        <WidgetTab dark={true} className="desk-only" /><br />
                        <BannerSection className="desk-only" Img="banner1.jpg" link="https://www.aranyaorganic.com/" /><br className="desk-only" />
                        <MostView category="LocalSection" Tag="ಸ್ಥಳೀಯ ಸುದ್ದಿ" />
                        <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div>
                        <MostView category="Editorial" Tag="ಸಂಪಾದಕೀಯ" />
                        <div className="banner2 mb30"><a target="_blank" href="https://www.aranyaorganic.com/"><img src={banner2} alt="thumb" /></a></div>
                        <MostView category="InterviewSection" Tag="ಸಂದರ್ಶನ" />
                        <BannerSection className="desk-only" Img="banner1.jpg" link="https://www.aranyaorganic.com/" /><br className="desk-only" />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default HomePage;