import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';
import FontAwesome from "../uiStyle/FontAwesome";
import { firestore } from "../../Config/FbConfig";
import { doc, getDoc } from 'firebase/firestore';

const PostCarousel = ({ className }) => {

    const [swiper, setSwiper] = useState(null);
    const [data, setdata] = useState([]);  
    const [loader, setloader] = useState(true);

    React.useEffect(() => {
        async function getData() {
            const docRef = doc(firestore, "Public", "TopSection");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []
                Object.entries(docSnap.data()).forEach(([key, value]) => {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                            date: value.Date != undefined ? value.Date : "9999",
                            Image: value.Image,
                            Header: value.Header
                        }
                    ))
                });
                setdata(Data.sort(function (b, a) { return a.date - b.date; }))
                setloader(false)

            } else {
                setdata([])
                setloader(false)
            }
        }
        getData()
    }, [])

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const params = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        slidesPerView: 3,
        spaceBetween: 20,
        loop: true,
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            }
        }
    };

    return (
        <div className={className ? className : ''}>
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="carousel_posts1 owl-carousel nav_style2 mb40 mt30">
                            {/*CAROUSEL START*/}
                            <div className="px-4 position-relative" s>
                                {loader === true ? "Loading.." :
                                    <Swiper getSwiper={setSwiper} className="trancarousel" {...params}>
                                        {data.map((item, i) => (
                                            <h4 className="side-section-limited-text"><Link to={'/specific-news/' + item.Id + '/' + item.Date} style={{color: "red"}}>{item.Header}</Link></h4>
                                        ))}
                                    </Swiper>
                                }
                            </div>
                        </div>
                        {/*CAROUSEL END*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostCarousel;