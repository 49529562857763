import React, { Fragment, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, Fade } from 'reactstrap';
import classnames from 'classnames';
import { Link } from "react-router-dom";
import { firestore } from "../../Config/FbConfig";
import { doc, getDoc } from 'firebase/firestore';


const WidgetTabPane = ({ arr, a_id, id, dark }) => {

    return (
        <Fade in={id === a_id}>
            <div className="widget tab_widgets">
                {arr.slice(0, 5).map((item, i) => (
                    <Fragment key={i}>
                        <div className="single_post widgets_small">
                            <div className="post_img">
                                <div className="img_wrap">
                                    <Link class="side-section-limited-image" to={'/specific-news/' + item.Id}><img src={item.Image} alt="thumb" /></Link>
                                </div>
                            </div>
                            <div className="single_post_text">
                                <div className="meta2 meta_separator1">{item.Date}</div>
                                <h4 className="side-section-limited-text"><Link to={'/specific-news/' + item.Id}>{item.Header}</Link></h4>
                            </div>
                        </div>
                        <div className="space-15" />
                        {dark ? <div className="border_white" /> : <div className="border_black" />}
                        <div className="space-15" />
                    </Fragment>
                ))}
            </div>
        </Fade>
    )
};

const WidgetTab = ({ className, dark }) => {

    const [activeTab, setActiveTab] = useState('1');

    const [Tab1data, setdata1] = useState([]);  
    const [Tab1loader, setloader1] = useState(true);

    const [Tab2data, setdata2] = useState([]);  
    const [Tab2loader, setloader2] = useState(true);

    const [Tab3data, setdata3] = useState([]);  
    const [Tab3loader, setloader3] = useState(true);


    React.useEffect(() => {

        async function getTab1Data() {
            const docRef = doc(firestore, "Public", "Cinema");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []
                Object.entries(docSnap.data()).forEach(([key, value]) => {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                            date: value.Date != undefined ? value.Date : "9999",
                            Image: value.Image,
                            Header: value.Header
                        }
                    ))
                });
                setdata1(Data.sort(function (b, a) { return a.date - b.date; }))
                setloader1(false)

            } else {
                setdata1([])
                setloader1(false)
            }
        }

        async function getTab2Data() {
            const docRef = doc(firestore, "Public", "Crime");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []
                Object.entries(docSnap.data()).forEach(([key, value]) => {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                            date: value.Date != undefined ? value.Date : "9999",
                            Image: value.Image,
                            Header: value.Header
                        }
                    ))
                });
                setdata2(Data.sort(function (b, a) { return a.date - b.date; }))
                setloader2(false)

            } else {
                setdata2([])
                setloader2(false)
            }
        }

        async function getTab3Data() {
            const docRef = doc(firestore, "Public", "Sports");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []
                Object.entries(docSnap.data()).forEach(([key, value]) => {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                            date: value.Date != undefined ? value.Date : "9999",
                            Image: value.Image,
                            Header: value.Header
                        }
                    ))
                });
                setdata3(Data.sort(function (b, a) { return a.date - b.date; }))
                setloader3(false)

            } else {
                setdata3([])
                setloader3(false)
            }
        }

        getTab1Data()
        getTab2Data()
        getTab3Data()
    }, [])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div className={`widget_tab md-mt-30 ${className}`}>
            <Nav tabs>
                <NavItem>
                    <Link to="/" className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>ಸಿನಿಮಾ</Link>
                </NavItem>
                <NavItem>
                    <Link to="/" className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>ಕ್ರೈಂ</Link>
                </NavItem>
                <NavItem>
                    <Link to="/" className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>ಕ್ರೀಡೆ</Link>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId='1'><WidgetTabPane dark={dark} a_id={activeTab} id="1" arr={Tab1data} /></TabPane>
                <TabPane tabId='2'><WidgetTabPane dark={dark} a_id={activeTab} id="2" arr={Tab2data} /></TabPane>
                <TabPane tabId='3'><WidgetTabPane dark={dark} a_id={activeTab} id="3" arr={Tab3data} /></TabPane>
            </TabContent>
        </div>
    );
};

export default WidgetTab;