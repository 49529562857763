import React, { Fragment, useState } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import { Link, NavLink } from "react-router-dom";
import SearchModal from "../SearchModal";
import SidebarMenu from "../SidebarMenu";

const menus = [
    {
        id: 1,
        linkText: 'ಮುಖಪುಟ',
        link: '/'
    },
    {
        id: 2,
        linkText: 'ರಾಜ್ಯ',
        link: '/news-list/StateNews'
    },
    {
        id: 3,
        linkText: 'ರಾಷ್ಟ್ರ',
        link: '/news-list/NationalNews'
    },
    {
        id: 4,
        linkText: 'ಕ್ರೈಂ',
        link: '/news-list/Crime'
    },
    {
        id: 5,
        linkText: 'ಸಿನಿಮಾ',
        link: '/news-list/Cinema'
    },
    {
        id: 6,
        linkText: 'ಜೀವನ ಶೈಲಿ',
        link: '/news-list/LifeStyle'
    },
    {
        id: 7,
        linkText: 'ಕ್ರೀಡೆ',
        link: '/news-list/Sports'
    },
    {
        id: 8,
        linkText: 'ಧಾರ್ಮಿಕ',
        link: '/news-list/Religion'
    },
    {
        id: 9,
        linkText: 'ಶಿಕ್ಷಣ',
        link: '/news-list/Education'
    },
    {
        id: 10,
        linkText: 'ಆರೋಗ್ಯ',
        link: '/news-list/Health'
    },
    {
        id: 11,
        linkText: 'ವಾಣಿಜ್ಯ',
        link: '/news-list/Business'
    },
    {
        id: 12,
        linkText: 'ಆಹಾರ',
        link: '/news-list/FoodSection'
    }
];

const menusDark = [
    {
        id: 1,
        linkText: 'Breaking News',
        link: '/'
    },
    {
        id: 2,
        linkText: 'State',
        link: '/state'
    },
    {
        id: 3,
        linkText: 'National',
        link: '/national'
    },
    {
        id: 4,
        linkText: 'Crime',
        link: '/crime'
    },
    {
        id: 3,
        linkText: 'Life Style',
        link: '/life-style'
    },
    {
        id: 4,
        linkText: 'Cimema',
        link: '/cimema'
    },
    {
        id: 5,
        linkText: 'Others',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 6,
                linkText: 'Education',
                link: '/education'
            },
            {
                id: 7,
                linkText: 'Religion',
                link: '/religion'
            },
            {
                id: 8,
                linkText: 'Health',
                link: '/health',
            },
        ]
    }
];

const MainMenu = ({ className, dark }) => {
    const [searchShow, setSearchShow] = useState(false);
    const [sideShow, setSideShow] = useState(false);

    const arr = dark ? menusDark : menus;
    return (
        <Fragment>
            <div className={`main-menu ${className ? className : ''} desk-only`} id="header">
                <Link to="#top" className="up_btn up_btn1">
                    <FontAwesome name="chevron-double-up" />
                </Link>
                <div className="main-nav clearfix is-ts-sticky">
                    <div className="container">
                        <div className="row justify-content-between">
                            <nav className="navbar navbar-expand-lg col-lg-12 align-self-center">
                                <div className="site-nav-inner">
                                    <div id="navbarSupportedContent"
                                        className="collapse navbar-collapse navbar-responsive-collapse">
                                        <ul className="nav navbar-nav" id="scroll">
                                            {arr.length > 0 ? arr.map((item, i) => (
                                                <li key={i}
                                                    className={`
                                                ${item.child ? 'dropdown' : ''}
                                                nav-item`}>
                                                    {item.child ? <NavLink onClick={e => e.preventDefault()} to="/"
                                                        className="menu-dropdown"
                                                        data-toggle="dropdown">{item.linkText}
                                                        <FontAwesome
                                                            name={item.icon} /></NavLink>
                                                        : <NavLink to={item.link} className="menu-dropdown"
                                                            data-toggle="dropdown"><b>{item.linkText}</b><FontAwesome
                                                                name={item.icon} /></NavLink>}

                                                    {item.child ?
                                                        <ul className="dropdown-menu" role="menu">
                                                            {item.submenu.map((sub_item, i) => (
                                                                <li key={i}
                                                                    className={`${sub_item.child ? 'dropdown-submenu' : null}
                                                        `}>
                                                                    {sub_item.child ?
                                                                        <NavLink onClick={e => e.preventDefault()}
                                                                            to="/">{sub_item.linkText}</NavLink>
                                                                        : <NavLink
                                                                            to={sub_item.link}>{sub_item.linkText}</NavLink>}
                                                                    {sub_item.third_menu ?
                                                                        <ul className="dropdown-menu">
                                                                            {sub_item.third_menu.map((third_item, i) => (
                                                                                <li key={i}><NavLink
                                                                                    to={third_item.link}>{third_item.linkText}</NavLink>
                                                                                </li>
                                                                            ))}
                                                                        </ul> : null}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        : null
                                                    }
                                                </li>
                                            )) : null}
                                        </ul>
                                    </div>
                                    <SidebarMenu sideShow={sideShow} setSideShow={setSideShow} menus={arr} />
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {searchShow ?
                <SearchModal setSearchShow={setSearchShow} searchShow={searchShow} />
                : null
            }
        </Fragment>
    );
};

export default MainMenu;