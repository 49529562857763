import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import Swiper from 'react-id-swiper';
import { firestore } from "../../Config/FbConfig";
import { doc, getDoc } from 'firebase/firestore';

const MostView = ({ no_margin, title, dark, category, Tag }) => {
    const [swiper, setSwiper] = useState(null);
    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    React.useEffect(() => {
        async function getData() {
            const docRef = doc(firestore, "Public", category);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []
                Object.entries(docSnap.data()).forEach(([key, value]) => {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                            date: value.Date != undefined ? value.Date : "9999",
                            Image: value.Image,
                            Header: value.Header
                        }
                    ))
                });
                setdata(Data.sort(function (b, a) { return a.date - b.date; }))
                setloader(false)

            } else {
                setdata([])
                setloader(false)
            }
        }
        getData()
    }, [])

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 1,
        slidesPerColumn: 7,
    };
    
    return (
        <div className={`widget tab_widgets ${no_margin ? '' : 'mb30'}`}>
            <h2 className="widget-title">{title ? title : Tag}</h2>
            <div className="post_type2_carousel multipleRowCarousel nav_style1">
                {/*CAROUSEL START*/}
                {loader === true ? "Loading.." :
                    <Swiper getSwiper={setSwiper} {...params}>
                        {data.map((item, i) => (
                            <div key={i} className="single_post2_carousel">
                                <div className="single_post widgets_small type8">
                                    <div className="post_img">
                                        <div className="img_wrap">
                                            <Link class="side-section-limited-image" to={'/specific-news/' + item.Id}>
                                                <img src={item.Image} alt="slider5" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="single_post_text">
                                        <p>{item.Date}</p>
                                        <small><Link to={'/specific-news/' + item.Id} className="side-section-limited-text">{item.Header}</Link></small>
                                    </div>
                                    <div className="type8_count">
                                        <h2>{item.id}</h2>
                                    </div>
                                </div>
                                <br/><div className="border_white" /><br/>
                            </div>
                        ))}
                    </Swiper>
                }
                <div className="navBtns">
                    <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left" /></div>
                    <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right" /></div>
                </div>
                {/*CAROUSEL END*/}
            </div>
        </div>
    );
};

export default MostView;