import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyASAgXqmKr1_ufy3krEODmnwSuVetVXeQM",
  authDomain: "editors-angle.firebaseapp.com",
  projectId: "editors-angle",
  storageBucket: "editors-angle.appspot.com",
  appId: "1:1043749116894:web:e6931d3e1d61ede4129b4f",
  measurementId: "G-46XS3JQKM8"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {auth, firestore , storage};