import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../_PrivateRoute';
import GeneralPage from '../GeneralPage';

import HomePage from "../HomePage";
import PostTwoPage from "../PostTwoPage";
import AboutUs from "../AboutUsPage";
import Policy from "../Policy";
import Contact from "../ContactUsPage";
import NotFoundPage from "../NotFoundPage";


const Routes = () => {
    return (
        <Switch>
            {/*home one routes*/}
            <PrivateRoute exact path="/specific-news/:id" parentClass="theme-1" component={PostTwoPage} />
            <PrivateRoute exact path="/news-list/:id" parentClass="theme-1" component={GeneralPage} />
            <PrivateRoute exact path="/about-editors-angle" parentClass="theme-1" component={AboutUs} />
            <PrivateRoute exact path="/privacy-policy" parentClass="theme-1" component={Policy} />
            <PrivateRoute exact path="/" parentClass="theme-1" component={HomePage} />
            <Route exact component={NotFoundPage} />
        </Switch>
    );
};
export default Routes