import React, { useState } from 'react';
import Heading from "../uiStyle/Heading";
import Swiper from 'react-id-swiper';
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import { firestore } from "../../Config/FbConfig";
import { doc, getDoc } from 'firebase/firestore';

const FeatureNews = ({ className, category, Tag }) => {

    const [swiper, setSwiper] = useState(null);
    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    React.useEffect(() => {
        console.log(category)
        async function getData() {
            const docRef = doc(firestore, "Public", category);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var Data = []
                Object.entries(docSnap.data()).forEach(([key, value]) => {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Date: value.Date != undefined ? new Date(value.Date.seconds * 1000 + value.Date.nanoseconds / 1000000).toDateString() : "N/A",
                            date: value.Date != undefined ? value.Date : "9999",
                            Image: value.Image,
                            Header: value.Header
                        }
                    ))
                });
                setdata(Data.sort(function (b, a) { return a.date - b.date; }))
                setloader(false)
            } else {
                setdata([])
                setloader(false)
            }
        }
        getData()
    }, [])

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 20
            }
        }
    };

    return (
        <div className={`feature_carousel_area mb40 ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading title={Tag} />
                    </div>
                </div>
                {/*CAROUSEL START*/}
                <div className="feature_carousel nav_style1">
                    {loader === true ? "Loading.." :
                        <Swiper getSwiper={setSwiper} {...params}>
                            {data.map((item, i) => (
                                <div key={i} className="single_post post_type6 post_type7">
                                    <div className="post_img gradient1">
                                        <Link to={'/specific-news/' + item.Id}><img className="feature-section-limited-image" src={item.Image} alt="thumb" /></Link>
                                    </div>
                                    <div className="single_post_text">
                                        <div className="text-colour-white">{ }</div>
                                        <h4>
                                            <Link to={'/specific-news/' + item.Id} className="feature-section-limited-text">
                                                <small>{item.Date}</small><br/>
                                                {item.Header}
                                            </Link>
                                        </h4>
                                    </div>
                                </div>
                            ))}
                        </Swiper>
                    }
                    <div className="navBtns">
                        <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left" /></div>
                        <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right" /></div>
                    </div>
                </div>
                {/*CAROUSEL END*/}
            </div>
        </div>
    );
};

export default FeatureNews;