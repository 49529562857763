import React from 'react';
import {Link} from "react-router-dom";

const BreadCrumb = (props) => {
    const {className = '', title, type} = props;
    const Name = {
        BreakingNews:  "Breaking",
        BreakingNewsLink:  "news-list/BreakingNews",

        Business: "Business",
        BusinessLink: "news-list/Business",
  
        Cinema: "Cinema",
        CinemaLink: "news-list/Cinema",

        Crime: "Crime",
        CrimeLink: "news-list/Crime",

        Editorial: "Editorial",
        EditorialLink: "news-list/Editorial",

        Education: "Education",
        EducationLink: "news-list/Education",

        FoodSection: "Food Section",
        FoodSectionLink: "news-list/FoodSection",

        Health: "Health",
        HealthLink: "news-list/Health",

        InterviewSection: "InterviewSection",
        InterviewSectionLink: "news-list/InterviewSection",

        LifeStyle: "Life Style",
        LifeStyleLink: "news-list/LifeStyle",

        LocalSection: "Local Section",
        LocalSectionLink: "news-list/LocalSection",

        NationalNews: "National News",
        NationalNewsLink: "news-list/NationalNews",

        Religion: "Religion",
        ReligionLink: "news-list/Religion",

        Sports: "Sports",
        SportsLink: "news-list/Sports",

        StateNews: "State News",
        StateNewsLink: "news-list/StateNews",

    }
    return (
        <div className={`inner_table ${className}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {
                            type != "List" ? 
                            <div className="bridcrumb"><Link to="/">Home</Link> / <Link to={"/" + Name[title+"Link"]}>{Name[title]}</Link> / Post</div> :
                            <div className="bridcrumb"><Link to="/">Home</Link> / {Name[title]}</div>
                        }
                    </div>
                </div>
                {props.children}
            </div>
        </div>
    );
};

export default BreadCrumb;