import React from 'react';
const BannerSection = ({className, Img, link}) => {
    return (
        <div className={`${className ? className : 'padding5050 fourth_bg'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 m-auto">
                        <div className="banner1">
                            <a href={link} target="blank">
                                <img src={process.env.PUBLIC_URL + 'img/' + Img} alt="bannerImg"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerSection;