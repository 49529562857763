import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import App from './containers/App/App';
import {BrowserRouter} from 'react-router-dom'
import store from './store'
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './doc/scss/master.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'swiper/swiper.scss'
import 'react-modal-video/scss/modal-video.scss';
import './index.css';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <HelmetProvider><App/></HelmetProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.register();
